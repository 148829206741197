import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import InfoSection from "../components/infoSection"
import Divider from "../components/divider"
import InstagramFeed from "../components/instagramFeed"

const PurchaseconfirmationPage = () => (
  <Layout>
    <Seo title="Purchase Confirmation" />

    <InfoSection textHeading="Vielen Dank für deine Bestellung!"
                 textSubtitle="Danke für deine Bestellung, wir werden anhand deiner Angaben den passenden Kaffee für dich auswählen." />

    <Divider />

    <InstagramFeed />

  </Layout>
)

export default PurchaseconfirmationPage
